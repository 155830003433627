import { inject } from "@angular/core";
import { ILogger } from "../logging/models/logger.model";
import { LOGGER } from "../logging/providers/logger.provider";

/**
 * Represents a base class for a store.
 * @template T - The type of data stored in the store.
 */
export abstract class Store<T> {
    /**
     * The logger service.
     */
    private readonly _logger: ILogger = inject(LOGGER);
    get logger(): ILogger {
        return this._logger;
    }

    /**
     * Loads the data from the store.
     * @returns A promise that resolves to an array of data.
     */
    protected abstract load(): Promise<T[]>;

    /**
     * Clears the data in the store.
     */
    protected abstract clear(): void;

    /**
     * Resets the store to its initial state.
     */
    protected abstract reset(): void;

    /**
     * Retrieves all the data from the store.
     * @returns An array of data.
     */
    protected abstract getAll(): T[];

    /**
     * Retrieves data from the store by its ID.
     * @param id - The ID of the data to retrieve.
     * @returns The data with the specified ID, or undefined if not found.
     */
    protected abstract getById(id: number): T | undefined;
}
